<template>
    <div class="obj-list wrapper ofh" style="padding-bottom:180px;" v-loading.fullscreen.lock="loading"
        :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="top-header">
            <h1>{{ $t('reports') }}</h1>
        </div>
        <div class=" all-reports">
            <el-row :gutter="24" type="flex" style="margin-left: -5px; margin-right: -5px; flex-wrap:wrap;">
                <el-col v-if="me.role.name == 'admin'" :md="8" class="reports-card" >
                    <div class="reports-item">
                        <div class="reports-head">
                            <h1>{{ $t('all_building_structures_reports') }}</h1>
                        </div>
                        <div class="report-item-body">
                            <div class="flex items-center justify-between report-download"
                                @click="DownloadExel('/building/generate/buildingstatistic/', 'Ҳисобот барча бино')">
                                <div class="flex items-center">
                                    <img width="20" class="mr-2" src="@/assets/img/icons/excel.svg" alt="">
                                    <p class="report-item-body-title"> {{ $t('download_report') }} </p>
                                </div>
                                <div class="flex items-center">
                                    <button>
                                        <i class="el-icon-download"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col v-if="me.role.name == 'admin'" :md="8" class="reports-card" >
                    <div class="reports-item">
                        <div class="reports-head">
                            <h1> {{ $t('regional_building_report') }} </h1>
                            <div class="w-full flex mt-3 gap-2">
                                <el-select :placeholder="$t('region')" v-model="filter_regions.region0" style="width: 150px;" clearable>
                                <el-option v-for="(item, i) in regions" :key="item.id"
                                    :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                    :value="i + 1">
                                </el-option>
                            </el-select>
                            </div>
                          
                        </div>
                        <div class="report-item-body">
                            <div class="flex items-center justify-between report-download bg-purple-500"
                                @click="DownloadExel(`/building/generate/buildinggroups/${regions[filter_regions.region0 - 1].id}/`, `Ҳудудий бинолар ҳисоботи ${regions[filter_regions.region0 - 1].name_cyr}`)"
                                v-if="filter_regions.region0">
                                <div class="flex items-center">
                                    <img width="20" class="mr-2" src="@/assets/img/icons/excel.svg" alt="">
                                    <p class="report-item-body-title">{{ $t('download_report') }}</p>
                                </div>
                                <div class="flex items-center">
                                    <button>
                                        <i class="el-icon-download"></i>
                                    </button>
                                </div>
                            </div> 
                            <div v-else class="report-download-warning" >
                                <i class="el-icon-warning-outline"></i> {{ $t('choose_report') }} 
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :md="8" class="reports-card" v-if="me.role.name == 'admin'">
                    <div class="reports-item">
                        <div class="reports-head">
                            <h1> {{ $t('hydrotechniks_report') }} </h1>
                        </div>
                        <div class="report-item-body">
                            <div class="flex items-center justify-between report-download"
                                @click="DownloadExel('/building/generate/reservoirstatistic/', 'Ҳисобот Гидротехник иншоотлар')">
                                <div class="flex items-center">
                                    <img width="20" class="mr-2" src="@/assets/img/icons/excel.svg" alt="">
                                    <p class="report-item-body-title">{{ $t('download_report') }}</p>
                                </div>
                                <div class="flex items-center">
                                    <button>
                                        <i class="el-icon-download"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <!-- for org_admin -->
                <el-col :md="8" class="reports-card"
                    v-if="me.role.name == 'org_admin' || me.role.name == 'ministry_admin'">
                    <div class="reports-item">
                        <div class="reports-head">
                            <h1> {{ $t('all_building_structures_reports') }} </h1>
                        </div>
                        <div class="report-item-body">
                            <div class="flex items-center justify-between report-download"
                                @click="DownloadExel('/building/generate/orgadmin/buildingstatistic/', 'Ҳисобот барча бино')">
                                <div class="flex items-center">
                                    <img width="20" class="mr-2" src="@/assets/img/icons/excel.svg" alt="">
                                    <p class="report-item-body-title">{{ $t('download_report') }}</p>
                                </div>
                                <div class="flex items-center">
                                    <button>
                                        <i class="el-icon-download"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col v-if="me.role.name == 'org_admin' || me.role.name == 'ministry_admin'" :md="8" class="reports-card">
                    <div class="reports-item">
                        <div class="reports-head">
                            <h1> {{ $t('regional_building_report') }} </h1>
                            <div class="w-full flex mt-3 gap-2">
                                <el-select :placeholder="$t('region')" class="region-select"
                                    v-model="filter_regions.region0" style="width: 150px;" clearable>
                                    <el-option v-for="(item, i) in regions" :key="item.id"
                                        :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                        :value="i + 1">
                                    </el-option>
                                </el-select>
                            </div>
                            
                        </div>
                        <div class="report-item-body">
                            <div class="flex items-center justify-between report-download"
                                @click="DownloadExel(`/building/generate/orgadmin/buildinggroups/${regions[filter_regions.region0 - 1].id}/`, `Ҳудудий бинолар ҳисоботи ${regions[filter_regions.region0 - 1].name_cyr}`)"
                                v-if="filter_regions.region0">
                                <div class="flex items-center">
                                    <img width="20" class="mr-2" src="@/assets/img/icons/excel.svg" alt="">
                                    <p class="report-item-body-title">{{ $t('download_report') }}</p>
                                </div>
                                <div class="flex items-center">
                                    <button>
                                        <i class="el-icon-download"></i>
                                    </button>
                                </div>
                            </div> 
                            <div v-else class="report-download-warning" >
                                <i class="el-icon-warning-outline"></i> {{ $t('choose_report') }} 
                            </div>
                        </div>
                    </div>
                </el-col>


                <el-col :md="me.role.name == 'admin' ? 12 : 8" class="reports-card">
                    <div class="reports-item">
                        <div class="reports-head">
                            <h1> {{ $t('an_incomplete_building_report') }} </h1>
                            <div class="flex items-center mt-3 gap-2 flex-wrap">
                                <el-select v-if="me.role.name == 'admin'" placeholder="Ташкилотлар" style="width: 150px;"  v-model="organization" clearable>
                                    <el-option v-for="(item, i) in organizations" :key="i" :label="item.name" :value="i + 1"></el-option>
                                </el-select>
                                <el-select :placeholder="$t('region')" :disabled="!organization"
                                    v-model="filter_regions.region1" style="width: 150px;" clearable>
                                    <el-option v-for="(item, i) in regions" :key="item.id"
                                        :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                        :value="i + 1">
                                    </el-option>
                                </el-select>
                            </div>

                        </div>
                        <div class="report-item-body">
                            <div v-if="filter_regions.region1" class="flex items-center justify-between report-download"
                                @click="DownloadExel(`/building/generate/incompletebuildings${organization == 1 ? '' : organization}/${regions[filter_regions.region1 - 1].id}/`, `${organizations[organization - 1].short} ${regions[filter_regions.region1 - 1].name_cyr}`)">
                                <div class="flex items-center">
                                    <img width="20" class="mr-2" src="@/assets/img/icons/excel.svg" alt="">
                                    <p class="report-item-body-title">{{ $t('download_report') }}</p>
                                </div>
                                <div class="flex items-center">
                                    <button>
                                        <i class="el-icon-download"></i>
                                    </button>
                                </div>
                            </div> 
                            <div v-else class="report-download-warning" >
                                <i class="el-icon-warning-outline"></i> {{me.role.name == 'admin' ? $t('choose_organisation_region') : $t('choose_report') }}
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :md="me.role.name == 'admin' ? 12 : 8" class="reports-card">
                    <div class="reports-item">
                        <div class="reports-head">
                            <h1> {{ $t('organisation_piace_all_report') }}</h1>
                            <div class="flex items-center gap-2 mt-3 flex-wrap">
                                    <el-select v-if="me.role.name == 'admin'" :placeholder="$t('organizations')" class="cut-text" style="width: 150px;" v-model="org_id" clearable>
                                        <el-option class="cut-text" v-for="(item, i) in OrganizarionsList" :key="i"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id"></el-option>
                                    </el-select>
                                    <el-select placeholder="Ташкилот" v-if="me.role.name == 'admin'" :disabled="!org_id"  v-model="org_child_id" style="width: 150px;" clearable>
                                        <el-option class="cut-text" v-for="(item, i) in OrganizarionsListChild" :key="i"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-select v-if="me.role.name === 'admin'" placeholder="Регион"
                                        v-model="filterRegion"  style="width: 150px;"   clearable>
                                        <el-option class="cut-text" v-for="(item, i) in regions" :key="i"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="i + 1"></el-option>
                                    </el-select>

                                    <el-select v-if="me.role.name === 'admin'" placeholder="Туман"
                                        :disabled="!filterRegion" v-model="filterDistrict" style="width: 150px;" clearable>
                                        <el-option class="cut-text" v-for="(item, i) in current_district" :key="i"
                                            :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </div> 
                        </div>
                        <div class="report-item-body">
                            <div v-if="org_child_id" class="flex items-center justify-between report-download"
                                @click="DownloadExel(`/building/generate/statistic/organization/${org_child_id}/`, `Ташкилот Ҳисоботи`)">
                                <div class="flex items-center">
                                    <img width="20" class="mr-2" src="@/assets/img/icons/excel.svg" alt="">
                                    <p class="report-item-body-title"> {{ $t('download_report') }}</p>
                                </div>
                                <div class="flex items-center">
                                    <button>
                                        <i class="el-icon-download"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-else class="report-download-warning" >
                                <i class="el-icon-warning-outline"></i> {{me.role.name == 'admin' ? $t('choose_organisation_region') : $t('choose_report') }}
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            region: '',
            loading: false,
            filter_regions: {
                region0: '',
                region1: '',
                region2: '',
                region3: '',
                region4: '',
            },
            filterRegion: '',
            filterDistrict: '',
            current_district: {},
            organization: "",
            organizations: [
                { name: 'Ўзбекистон Республикаси Халқ таълими вазирлиги', short: 'ЎзРес Халқ таълими вазирлиги', id: 1, org_id: '2675' },
                { name: 'Ўзбекистон Республикаси  Соғлиқни сақлаш вазирлиги', short: 'ЎзРес Соғлиқни сақлаш вазирлиги', id: 2, org_id: '3737' },
                { name: 'Ўзбекистон Республикаси Мактабгача таълим вазирлиги', short: 'ЎзРес Мактабгача таълим вазирлиги', id: 3, org_id: '8209' },
                { name: 'Ўзбекистон Республикаси Уй-жой коммунал хизмат кўрсатиш вазирлиги', short: 'ЎзРес Халқ  Уй-жой коммунал хизмат кўрсатиш вазирлиги', id: 4, org_id: '4061' },
            ],
            OrganizarionsList: [],
            OrganizarionsListChild: [{}],
            org_id: '',
            org_child_id: null

        };
    },
    computed: {
        regions() {
            return this.$store.state.dictionary.region;
        },
        me() {
            return this.$store.state.me;
        },
    },
    watch: {
        me() {
            if (this.me.role.name != 'admin' && this.me.role.name != 'org_admin') {
                this.$router.push({ name: 'notFoundPage' })
            }
            else if (this.me.role.name == 'org_admin' || this.me.role.name == 'ministry_admin') {
                if (this.me.organization && this.me.organization.id == 2675) {
                    this.organization = 1
                }
                if (this.me.organization && this.me.organization.id == 3737) {
                    this.organization = 2
                }
                if (this.me.organization && this.me.organization.id == 8209) {
                    this.organization = 3
                }
                if (this.me.organization && this.me.organization.id == 4061) {
                    this.organization = 4
                }
            }
            if (this.me.role.name == 'admin') {
                axios.get('/organization/?limit=15&level=1')
                    .then((response) => {
                        this.OrganizarionsList = response.data.results;
                        // this.loading = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
            else if (this.me.role.name == 'org_admin' || this.me.role.name == 'ministry_admin') {
                this.org_child_id = this.me.organization.id
            }
        },
        org_id(val) {
            this.org_child_id = null
            axios.get(`/organization/${val}/`)
                .then((response) => {
                    this.OrganizarionsListChild = response.data.children;
                })
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        organization() {
            this.filter_regions.region1 = ""
        },
        filterRegion(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.filterDistrict = ''
                this.getDistrict()
            }
        }
    },
    mounted() {
        if (this.me.role.name != 'admin' && this.me.role.name != 'org_admin' && this.me.role.name !== 'ministry_admin') {
            this.$router.push({ name: 'notFoundPage' })
        }
        else if (this.me.role.name == 'org_admin' || this.me.role.name == 'ministry_admin') {
            if (this.me.organization && this.me.organization.id == 2675) {
                this.organization = 1
            }
            if (this.me.organization && this.me.organization.id == 3737) {
                this.organization = 2
            }
            if (this.me.organization && this.me.organization.id == 8209) {
                this.organization = 3
            }
            if (this.me.organization && this.me.organization.id == 4061) {
                this.organization = 4
            }
        }
        if (this.me.role.name == 'admin') {
            axios.get('/organization/?limit=15&level=1')
                .then((response) => {
                    this.OrganizarionsList = response.data.results;
                    // this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
        else if (this.me.role.name == 'org_admin' || this.me.role.name == 'ministry_admin') {
            this.org_child_id = this.me.organization.id
        }
    },
    methods: {
        DownloadExel(baseURL, name) {
            this.loading = true
            let url = `${baseURL}`

            if (this.filterRegion && !this.filterDistrict) {
                url += `?region=${encodeURIComponent(this.filterRegion)}`
            }
            else if (this.filterRegion && this.filterDistrict) {
                url += `?region=${encodeURIComponent(this.filterRegion)}&district=${encodeURIComponent(this.filterDistrict)}`
            }
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `${name}` + ".xlsx");
                document.body.appendChild(fileLink);
                fileLink.click();
            })
                .finally(() => {
                    this.loading = false
                })
        },
        getDistrict() {
            axios
                .get(`/dictionary/district/?region=${this.filterRegion}`)
                .then((response) => {
                    this.current_district = response.data;
                    console.log(this.current_district);
                })
        },
    },
};
</script>

<style lang="scss" scoped>
.el-select-org {
    .region-select {
        margin: 0 !important;
    }

    div {
        .el-select {
            width: 150px;
        }
    }
}

.w-33 {
    width: 33%;
    min-width: 300px;
}

.all-reports {
    width: 100%;
}

.reports-card {
    padding: 5px !important;
    min-width: 400px;
}




.reports-head {
    font-weight: 600; 
    min-height: 48px;
    font-size: 14px;
}

.report-item-body {
    padding-top: 16px;
}

.report-item-body-title {
    font-size: 14px;
    font-weight: 600;
    margin-top: 2px;
}

.reports-item {
    width: 100%;
    padding: 15px;
    background: #fff; 
    border-radius: 15px;
    min-height: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1);
}

.report-download {
    cursor: pointer;
    border-radius: 16px; 
    padding: 5px 5px 5px 15px;
    background: #6366f1;
    color: #fff;
    font-size: 14px;
    button{
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 50%;
        color: #6366f1;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.report-download-warning{
    padding: 12px 16px;
    background: #f4f4f4;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #FFB800;
    display: flex;
    align-items: center;
    gap: 10px;
}

.report-download:hover {
    opacity: 1;
}

.region-select {
    margin-left: 10px;
    max-width: 150px !important;
    min-width: 150px !important;
}

.cut-text {
    text-overflow: ellipsis !important; 
    max-width: 700px !important;
    white-space: nowrap !important;
}
</style>
